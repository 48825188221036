@mixin transition {
	-ms-transition: all 0.5s ease-out;
	-moz-transition: all 0.5s ease-out;
	-webkit-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

@mixin transition-speed {
	-ms-transition: all 0.10s ease-out;
	-moz-transition: all 0.10s ease-out;
	-webkit-transition: all 0.10s ease-out;
	-o-transition: all 0.10s ease-out;
	transition: all 0.10s ease-out;
}

@mixin fadein {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.25s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}