@import "./mixin.scss";
@import url("./fonts/fonts.css");

/**************************/
/*******  Variables  ******/
/**************************/

/** Colors **/
$grey-efe: #efefef;
$grey-666: #666666;
$grey-f7f: #f7f7f7;
$black: #333333;
$red: #da413f;
$green: #3fda8e;
$grey-6d6: #6d6d6d;
$blue: #0f4d95;
$blue-light: #0071bb;
$blue-background: #2c4267;

/** Sizes **/
$size-14: 0.8rem;
$size-16: 0.9rem;
$size-18: 1.1rem;
$size-24: 1.4rem;
$size-36: 2.4rem;
$size-40: 3rem;
$gradient-text: linear-gradient(-45deg, $red 0%, $blue 100%);

/** Fonts **/
$din-bold: "DIN-BOLD", sans-serif;
$din-med: "DIN-MED", sans-serif;
$din-light: "DIN-LIGHT", sans-serif;
$helvetica-bold: "HELV-NEUE-BLD", sans-serif;
$helvetica-reg: "Helvetica Neue", sans-serif;
$helvetica-light: "HELV-NEUE-LIGHT", sans-serif;

.min-heigth-50 {
  min-height: 50%;
}
/***********************/
/*******  Global  ******/
/***********************/

html,
body,
#root,
.App {
  height: 100%;
}

ol, ul {
  font-size: 1.12rem;
  font-family: $helvetica-reg;
}

.sticky-top-validation {
  top: 100px !important;
}

#root  {
  height: 100%;
}
.App {
  height: 100%;
}
html,
.container {
  min-width: 768px;
}

h2 {
  font-family: $din-bold;
  font-size: $size-40;
}

h2 {
  font-family: $din-light;
  font-size: $size-36;
}

.sticky-top {
  &.sticky-top-validation {
    top: 100px !important;
  }

  &.sticky-top-menu-validation {
    top: 220px !important;
  }
  &.sticky-top-menu {
    top: 120px !important;
  }
}

h3 {
  font-family: $din-light;
  font-size: $size-24;
}

h4 {
  font-family: $din-med;
  font-size: $size-24;
  color: $black !important;
}

p {
  font-family: $helvetica-reg;
  font-size: $size-18;
  color: $black;
}

section[name="abilities"] {
  :not(.underligne) {
    form[name="abilities"] {
      overflow: inherit !important;

      .css-1l76avi-control,
      .css-zfes0w-control,
      .css-zfes0w-control:hover {
        min-height: 60px;
        height: inherit !important;
      }
    }
  }
}

.form-group {
  overflow: inherit !important;

  .css-1l76avi-control,
  .css-zfes0w-control,
  .css-zfes0w-control:hover {
    min-height: 60px;
    height: inherit !important;
  }
}

/******** Text color *******/
.text-muted {
  color: $grey-6d6;
}

.text-warning {
  color: $red;
}

/******** Form *******/
form {
  label {
    color: $black;
  }

  .form-control {
    border: 2px solid $grey-efe;
    font-family: $helvetica-reg;
    font-size: $size-18;
    color: $black;

    &::placeholder {
      font-family: $helvetica-reg;
      font-size: $size-18;
      color: $grey-666;
      font-style: italic;
    }

    &[disabled] {
      cursor: not-allowed;
      background-color: $grey-f7f;
    }

    &.is-invalid {
      background-image: none;
      padding: 0.375rem 0.75rem;
      border-color: $red;
      color: $red;
    }
  }

  input.form-control {
    height: 60px;
    line-height: 1;
  }

  textarea.form-control {
    min-height: 200px;
    height: inherit;
    padding: 1rem;
  }

  .invalid-feedback {
    margin-top: 1rem;
    font-family: $helvetica-reg;
    font-size: $size-18;
    color: $red;
  }
}

/******** Form space underCard *******/

section {
  .card {
    margin-bottom: 20px;
  }
}

/******** Background *******/
.background-opacity-black {
  background: rgba(0, 0, 0, 0.25);
}

/******** Button *******/
.btn {
  cursor: pointer;

  &.btn-civipol {
    @include transition;
    border-radius: 50px;
    color: white;
    background-image: $gradient-text;
    border: $red;
    padding: 0.5rem 1.5rem 0.25rem;
    font-family: $din-med;
    text-transform: uppercase;
    font-weight: bold;
    transition: all 0.3s ease 0s;
    font-size: $size-18;

    &:hover {
      color: white;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      background-image: $gradient-text;
    }
  }

  &.btn-civipol-white {
    @include transition;
    color: black;
    border-radius: 20px;
    border-width: 2px;
    border-color: $grey-efe;
    background-color: white;
    padding: 0.5rem 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    &.previous {
      width: 40px;
      height: 40px;
      padding: 0;
      text-indent: -9999px;
      background: transparent url("../images/button/icon-previous.svg") no-repeat center;
      background-size: cover;
      border-width: 0;
    }
    &.next {
      width: 40px;
      height: 40px;
      padding: 0;
      text-indent: -9999px;
      background: transparent url("../images/button/icon-next.svg") no-repeat center;
      background-size: cover;
      border-width: 0;
    }

    &:hover {
      background-color: $grey-efe;
    }
  }

  &.btn-tag {
    color: $blue;
    text-transform: capitalize;
    font-weight: 400;
  }

  &.btn-arrow {
    font-family: $helvetica-reg;
    font-size: $size-18;
    text-transform: uppercase;
    color: $grey-666;
    outline: none;
    box-shadow: none;
    display: inline-flex;
    align-items: center;

    &:after {
      @include transition-speed;
      content: "";
      display: inline-flex;
      width: 15px;
      height: 15px;
      margin-left: 0.5rem;
      background: transparent url("../images/search/icon-chevron.svg") no-repeat center;
      background-size: contain;
      transform: rotate(-90deg);
    }

    &[aria-expanded="true"] {
      &:after {
        @include transition-speed;
        transform: rotate(90deg);
      }
    }
  }

  &.btn-export {
    display: flex;
    align-items: center;
    padding-left: calc(1.5rem - 7.5px);

    &:before {
      content: "";
      display: inline-flex;
      width: 15px;
      height: 20px;
      margin-right: 0.5rem;
      background: transparent url("../images/button/icon-export.svg") no-repeat center;
    }
  }

  &.btn-save {
    display: flex;
    align-items: center;
    padding-left: calc(1.5rem - 7.5px);

    &:before {
      content: "";
      display: inline-flex;
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
      background: transparent url("../images/button/icon-save.svg") no-repeat center;
    }
  }

  &.btn-add {
    display: flex;
    align-items: center;
    padding-left: calc(1.5rem - 7.5px);

    &:before {
      content: "";
      display: inline-flex;
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
      background: transparent url("../images/button/icon-add.svg") no-repeat center;
    }
  }

  &.btn-reference {
    @include transition;
    border-radius: 20px;
    font-size: $size-14;
    font-family: $helvetica-reg;
    padding: 0.15rem 0.75rem;
    color: white;
    background-color: $grey-666;
    border: 2px solid $grey-666;

    &:hover {
      color: $grey-666;
      background-color: white;
    }
  }

  &.btn-remove {
    width: 40px;
    height: 40px;
    margin-left: 0.5rem;
    background: transparent url("../images/button/icon-remove.svg") no-repeat center;
  }

  &.btn-green {
    @include transition;
    border-radius: 50px;
    color: white;
    background-color: $green;
    border: $green;
    padding: 0.5rem 1.5rem 0.25rem;
    font-family: $din-med;
    text-transform: uppercase;
    font-weight: bold;
    transition: all 0.3s ease 0s;
    font-size: $size-18;

    &:hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }
  }

  &.btn-red {
    @include transition;
    border-radius: 50px;
    color: white;
    background-color: $red;
    border: $red;
    padding: 0.5rem 1.5rem 0.25rem;
    font-family: $din-med;
    text-transform: uppercase;
    font-weight: bold;
    transition: all 0.3s ease 0s;
    font-size: $size-18;

    &:hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }
  }

  &.btn-blue {
    border-radius: 50px;
    color: white;
    background-color: $blue;
    border: $blue;
    padding: 0.5rem 1.5rem 0.25rem;
    font-family: $din-med;
    text-transform: uppercase;
    font-weight: 400;
    font-size: $size-18;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.blue {
  color: $blue !important;
}

.black {
  color: $black !important;
}

/**********************/
/*******  Login  ******/
/**********************/
#login {
  background: $blue-background url("../images/login/background-login.jpg") no-repeat center;
  background-size: cover;
  height: 100vh;
  .logo {
    height: 70px;
  }

  .form {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    overflow: hidden;

    .header-login {
      height: 100px;
      background: $blue url("../images/login/form-header-login.jpg") no-repeat center;
      background-size: cover;

      h1 {
        font-size: $size-18;
      }
    }

    .form-login {
      border: 2px solid $grey-efe;
      border-top: none;
    }
  }
}

/************************************/
/*******  Dashboard & Experts  ******/
/************************************/

#header {
  background: $blue-background url("../images/header/background_header.jpg") no-repeat center;
  background-size: cover;
  height: 280px;

  .top-content {
    #top-bar {
      height: 40px;

      .welcome {
        font-size: $size-14;

        &:before {
          content: "";
          display: inline-flex;
          background: transparent url("../images/topbar/icon-profile.svg");
          width: 15px;
          height: 15px;
          margin-right: 0.5rem;
        }

        &:after {
          content: "";
          display: inline-flex;
          background-color: white;
          width: 1px;
          height: 15px;
          margin: 0 0.5rem;
        }
      }

      .logout {
        font-size: $size-14;
      }
    }

    #main-menu {
      height: 100px;
      @include transition;

      &.navbar-expand > .container,
      .navbar-expand > .container-fluid {
        padding-right: 15px;
        padding-left: 15px;
      }

      .navbar-brand {
        width: 170px;
        height: 80px;
        background: transparent url("../images/logo/logo-civipol-admin-white.svg") no-repeat center;
        background-size: contain;
      }

      .nav-item {
        position: relative;

        .nav-link {
          color: white;
          font-family: $helvetica-reg;
          font-size: $size-18;
        }

        &:last-of-type {
          .nav-link {
            padding-right: 0;
          }
        }

        &:hover {
          &:after {
            @include fadein;
            position: absolute;
            bottom: 0;
            left: 0.5rem;
            right: 0;
            content: "";
            display: block;
            width: calc(100% - 1rem);
            height: 5px;
            background-image: $gradient-text;
          }
        }

        &.active {
          font-family: $helvetica-bold;

          &:after {
            position: absolute;
            bottom: 0;
            left: 0.5rem;
            right: 0;
            content: "";
            display: block;
            width: calc(100% - 1rem);
            height: 5px;
            background-image: $gradient-text;
          }
        }
      }

      &.fixed-top {
        @include transition;

        .navbar-brand {
          background: transparent url("../images/logo/logo-civipol-admin.svg") no-repeat center;
          background-size: contain;
        }

        .nav-link {
          color: $black;
        }
      }
    }
  }

  #introduction {
    &.padding-top {
      padding-top: 100px;
    }

    .title-page {
      font-family: $din-bold;
      font-size: $size-40;
    }

    .breadcrumb {
      padding: 0;
      background: none;

      .breadcrumb-item {
        display: flex;
        align-items: center;
        font-family: $din-light;
        font-size: $size-24;

        & + .breadcrumb-item::before {
          content: "";
          width: 15px;
          height: 30px;
          background: transparent url("../images/header/breadcrumb/icon-chevron.svg") no-repeat center bottom;
          background-size: auto;
          margin: 0 0.25rem;
        }

        a {
        }
      }
    }
  }
}

#banner,
#content {
  /****************************/
  /*******  Global Card  ******/
  /****************************/
  .card {
    border: 2px solid $grey-efe;
    border-radius: 3px;

    &.overflow {
      max-height: 300px;
      overflow-y: scroll;
    }

    &.animate:hover {
      @include transition;
      cursor: pointer;
      background-color: $grey-efe;
      border-color: $grey-efe;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }

    .card-img {
      height: 120px;
    }

    .card-title {
      font-family: $din-bold;
      font-size: $size-18;
      color: $black;
    }

    .card-text {
      font-family: $helvetica-reg;
      font-size: $size-18;
      color: $grey-666;
    }

    .theme-container {
      background-color: #f7f7f7;
      padding: 15px;
      display: inline-block;
      font-family: $helvetica-reg;
    }
  }

  .information-expert,
  .information-project {
    label {
      font-family: $helvetica-reg;
      font-size: $size-14;
      color: $grey-666;
    }

    h4 {
      font-family: $din-med;
      font-size: $size-18;
      color: $black;
    }
  }

  .profile-picture {
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // background-image: url("../images/expert-card/portrait-male-default@2x.png");
    &.profile-picture-large {
      width: 100px;
      height: 100px;
    }
  }

  .profile-badge {
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
  }

  .profile-badges {
    position: absolute;
    top: 0;
    right: 15px;

    .profile-badge {
      width: 50px;
      height: 50px;

      &.badge-civipol {
        background-image: url("../images/recommandations/badge_civipol_check.svg");
      }
      &.badge-DG {
        background-image: url("../images/recommandations/badge_partenaire.svg");
      }
    }
  }

  input[type="checkbox"] + label.profile-badge {
    opacity: 0.65;
    &.badge-civipol {
      background-image: url("../images/recommandations/badge_civipol_check_grey.svg");
    }
    &.badge-DG {
      background-image: url("../images/recommandations/badge_partenaire_grey.svg");
    }
  }

  input[type="checkbox"]:checked + label.profile-badge {
    opacity: 1;
    &.badge-civipol {
      background-image: url("../images/recommandations/badge_civipol_check.svg");
    }
    &.badge-DG {
      background-image: url("../images/recommandations/badge_partenaire.svg");
    }
  }

  .profile-name {
    font-family: $din-light;
    font-size: $size-24;
    color: $black !important;
  }

  .profile-view,
  .profile-creation {
    font-family: $helvetica-reg;
    font-size: $size-14;
    color: $grey-666;
  }

  .profile-status {
    font-family: $din-med;
    font-size: $size-18;
    color: $black;
    line-height: 1;

    .dot {
      width: 12px;
      height: 12px;
      display: inline-flex;
      background-color: $black;
    }

    $color-list: $red $blue-light $green $grey-666 $black;
    @each $color in $color-list {
      &.status[data-color="#{$color}"] {
        color: $color;

        .dot {
          background-color: $color;
        }
      }
    }
  }

  .icon-number {
    position: relative;
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-family: $din-med;
    font-size: $size-24;

    b {
      font-size: $size-24;
      z-index: 2;
      color: black;
    }

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 3rem;
      height: 3rem;
      background: $grey-f7f;
      border-radius: 50%;
      z-index: 1;
    }
  }

  /**************************/
  /*******  Scrollspy  ******/
  /**************************/

  .scrollspy {
    .card {
      cursor: pointer;
      p {
        // font-family: $din-med;
        font-size: 1.2rem;
      }
      span {
        // font-family: $din-med;
        font-size: 1.2rem;
      }
      &.card-active {
        background-image: $gradient-text;
        border-width: 0;
        p {
          // font-family: $din-med;
          color: white;
          font-size: 1.2rem;
        }
        span {
          // font-family: $din-med;
          color: white;
          font-size: 1.2rem;
        }
      }
    }
  }

  /***************************/
  /*******  Alert Card  ******/
  /***************************/

  .alert-card {
    .close {
      position: absolute;
      top: 1rem;
      right: 1rem;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .logotype {
      height: 200px;
      margin-right: 2rem;
    }
  }

  /*****************************/
  /*******  Quick actions ******/
  /*****************************/

  .quick-actions {
    .section-button {
      .btn-block + .btn-block {
        margin-top: inherit;
      }
    }
  }

  /***********************************/
  /*******  Monitoring activity ******/
  /***********************************/

  .activity-monitoring {
    .card-body {
      color: $grey-666;
      padding: 1rem;

      .card-title {
        font-family: $din-light;
        font-size: $size-24;
      }

      .card-text {
        font-family: $helvetica-reg;
        font-size: $size-14;
      }
    }
  }

  /*************************/
  /*******  Big title ******/
  /*************************/

  .big-title {
    font-family: $din-light;
    font-size: $size-36;
    position: relative;
    line-height: 1;

    &:after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      display: block;
      width: 75px;
      height: 5px;
      background-image: $gradient-text;
    }

    small {
      font-family: $helvetica-reg;
      font-size: $size-18;
      color: $grey-666;
    }
  }

  /***********************************/
  /*******  Title black section ******/
  /***********************************/

  .title-section-black {
    font-family: $din-light;
    font-size: $size-24;
    color: $black;
  }

  /**********************************/
  /*******  Title blue section ******/
  /**********************************/

  .title-section-blue {
    font-family: $din-med;
    font-size: $size-18;
    color: $blue;
  }

  /********************/
  /*******  Tabs ******/
  /********************/

  .tabs {
    .nav-tabs {
      background-color: $grey-f7f;

      .nav-link {
        padding: 1.5rem 2rem;
        border-color: $grey-efe $grey-efe white;
        border-bottom: 2px $grey-f7f;
        border-radius: 0;
        position: relative;
        font-family: $din-med;
        font-size: $size-18;
        color: $black;

        &:hover {
          background-color: #fbfbfb;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 5px;
        }

        &.active:after {
          background-image: $gradient-text;
        }
      }
    }
  }

  /*********************/
  /******* Search ******/
  /*********************/

  .search {
    .input-search {
      .form-control {
        height: 40px;
      }
    }

    #advanced-history-search {
      #advanced-search {
        @include transition-speed;

        .bg-grey {
          background-color: $grey-f7f;

          .search-title {
            font-family: $din-med;
            font-size: $size-18;
            color: $black;
          }
        }
      }
    }
  }

  /******************************/
  /******* Custom checkbox ******/
  /******************************/

  .custom-control {
    &.custom-checkbox {
      .custom-control-label {
        cursor: pointer;
        outline: none !important;

        &::before {
          border-radius: 3px;
          border-color: $grey-666;
          border-width: 2px;
        }

        &::selection,
        &:focus {
          outline: none !important;
        }

        &.is-invalid {
          color: $red;

          &::before {
            border-color: $red;
          }
        }
      }

      .custom-control-input[disabled] ~ .custom-control-label::before {
        border-color: $grey-efe;
        background-color: $grey-f7f;
        opacity: 0.8;
      }

      .custom-control-input:checked {
        & ~ .custom-control-label::before {
          color: white;
          border-color: $grey-666;
          background-color: white;
        }

        & ~ .custom-control-label {
          &::after {
            background-image: url("../images/form/icon-check-black.svg");
            background-size: 80%;
            background-position: center;
          }

          &.checkbox-red::after {
            background-image: url("../images/form/icon-check-red.svg");
          }
        }
      }

      .custom-control-input[disabled]:checked ~ .custom-control-label::before {
        border-color: $grey-efe;
        background-color: $grey-f7f;
      }

      &.custom-checkbox-fat {
        padding-left: 2.5rem;
        min-height: 2.5rem;
        line-height: 2.5rem;

        .custom-control-label {
          outline: none !important;

          &::before {
            border-color: $grey-efe;
            background-color: transparent;
          }

          &.is-invalid::before {
            border-color: $red;
          }

          &::before,
          &::after {
            width: 2rem;
            height: 2rem;
            left: -2.5rem;
          }
        }

        .custom-control-input:checked {
          & ~ .custom-control-label::before {
            border-color: $grey-efe;
          }

          & ~ .custom-control-label.is-invalid::before {
            border-color: $red;
          }

          & ~ .custom-control-label::after {
            background-image: url("../images/form/icon-check-black.svg");
            background-size: 70%;
          }

          & ~ .custom-control-label.is-invalid::after {
            background-image: url("../images/form/icon-check-red.svg");
          }
        }
      }
    }

    &.custom-switch {
      padding-left: 4rem;
      min-height: calc(1.75rem + 8px);

      .custom-control-label {
        outline: none !important;
        line-height: calc(1.75rem + 8px);

        &::before {
          background-color: $grey-666;
          border-width: 0;
          left: -4rem;
          width: 3.5rem;
          height: 1.75rem;
          border-radius: 50px;
        }

        &:after {
          cursor: pointer;
          @include transition-speed;
          background-color: white;
          top: calc(0.25rem + 4px);
          left: calc(-4rem + 4px);
          width: calc(1.75rem - 8px);
          height: calc(1.75rem - 8px);
          border-radius: 50px;
        }

        &::selection,
        &:focus {
          outline: none !important;
        }
      }

      .custom-control-input[disabled] {
        & ~ .custom-control-label {
          &:before {
            background-image: none;
            background-color: $grey-f7f;
          }

          &:after {
            background-color: $grey-666;
            opacity: 0.6;
          }
        }
      }

      .custom-control-input:checked {
        & ~ .custom-control-label {
          &:before {
            background-image: linear-gradient(-90deg, $red 0%, $blue 100%);
            color: white;
          }

          &:after {
            -webkit-transform: translateX(1.75rem);
            transform: translateX(1.75rem);
          }
        }
      }

      .custom-control-input[disabled]:checked {
        & ~ .custom-control-label {
          &:before {
            background-image: none;
            background-color: $grey-f7f;
          }

          &:after {
            background-color: $grey-666;
            opacity: 1;
          }
        }
      }
    }

    &.custom-radio {
      .custom-control-label {
        cursor: pointer;
        outline: none !important;

        &::before {
          border-color: $grey-666;
          border-width: 2px;
        }

        &::selection,
        &:focus {
          outline: none !important;
        }

        &.is-invalid {
          color: $red;

          &::before {
            border-color: $red;
          }
        }
      }

      .custom-control-input[disabled] ~ .custom-control-label::before {
        border-color: $grey-efe;
        background-color: $grey-f7f;
        opacity: 0.8;
      }

      .custom-control-input[disabled]:checked ~ .custom-control-label::after {
        opacity: 0.6;
      }

      &.custom-radio-fat {
        padding-left: 2.5rem;
        min-height: 2.5rem;
        line-height: 2.5rem;

        .custom-control-label {
          outline: none !important;

          &::before {
            border-color: $grey-efe;
            background-color: transparent;
          }

          &.is-invalid::before {
            border-color: $red;
          }

          &::before {
            width: 2rem;
            height: 2rem;
            left: -2.5rem;
          }

          &::after {
            width: 2rem;
            height: 2rem;
            left: -2.5rem;
            border: 3px solid transparent;
          }
        }

        .custom-control-input:checked {
          & ~ .custom-control-label::before {
            border-color: $grey-efe;
          }

          & ~ .custom-control-label.is-invalid::before {
            border-color: $red;
          }

          & ~ .custom-control-label::after {
            background-image: url("../images/form/icon-radio-black.svg");
            background-size: 70%;
          }

          & ~ .custom-control-label.is-invalid::after {
            background-image: url("../images/form/icon-radio-red.svg");
          }
        }
      }
    }
  }

  /*************************/
  /******* Pagination ******/
  /*************************/

  .pagination {
    .page-item {
      position: relative;
      margin: 0 0.25rem;

      &.disabled {
        cursor: not-allowed;
      }

      &.previous {
        margin-right: 1.25rem;
      }

      &.next {
        margin-left: 1.25rem;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40px;
        height: 40px;
        transform: translate(-50%, -50%);
        background-color: $grey-efe;
        border-radius: 50%;
      }

      .page-link {
        border: none;
        color: $black;
        padding: 1rem;
        background: none;
        font-family: $helvetica-reg;
        font-size: $size-18;
        box-shadow: none;

        &.page-link-arrow {
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 20px;
            transform: translate(-50%, -50%);
            background: transparent url("../images/button/icon-chevron.svg") no-repeat center;
          }

          &.next {
            &:before {
              transform: translate(-50%, -50%) rotate(180deg);
            }
          }
        }

        // &.previous {
        //   position: relative;
        //   text-indent: -9999px;

        //   &:before {
        //     content: "";
        //     display: block;
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     width: 10px;
        //     height: 20px;
        //     transform: translate(-50%, -50%);
        //     background: transparent url("../images/button/icon-chevron.svg") no-repeat center;
        //   }
        // }
      }

      &.active {
        &:before {
          background-image: linear-gradient(-135deg, #9e292e 30%, #15326a 100%);
        }

        .page-link {
          color: white;
          font-weight: bold;
        }
      }
    }
  }

  /***************************/
  /******* Form Civipol ******/
  /***************************/

  .form-civipol {
    .card-body {
      padding: 2rem;
    }
  }

  #banner {
  }
}

#footer {
  // position: fixed;
  bottom: 0;
  width: 100%;
  .footer-section-civipol {
    background: $blue-background url("../images/footer/background-footer.jpg") no-repeat center;
    background-size: cover;
  }

  .footer-section-copyright {
    background-color: $grey-efe;
    font-family: $helvetica-reg;
    font-size: $size-16;
    color: $black;

    a {
      color: $black;
    }
  }
}

/**
  TAGS SELECTION
*/

/*************************/
/******* Tags cloud ******/
/*************************/

.tags-cloud {
  max-height: 260px;
  overflow-y: scroll;
}

.expertise-selection {
  .tag-selected {
    min-height: 40px;
    border-radius: 20px;
    background-color: #0f4d95;

    p {
      font-family: $din-med;
      font-size: 18px;
      line-height: 23px;
      color: white;
    }
  }

  .btn-delete-white {
    cursor: pointer;
  }

  .tag-fields {
    background-color: #f7f7f7;
    border-radius: 3px;
    padding: 1rem;
    text-align: left;
    max-height: 300px;
    overflow-y: scroll;
    border-bottom: 2px solid #f7f7f7;

    .tag-btn {
      color: #0f4d95;
      padding: 0.5rem 1rem;
      border-radius: 20px;
      background-color: white;
      border: 2px solid $grey-efe;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1.4;
      font-size: $size-18;

      &:before {
        content: "";
        display: inline-flex;
        width: 20px;
        height: 20px;
        background: transparent url("../images/button/icon_addSkill.svg") no-repeat center;
        background-size: contain;
        margin-right: 0.5rem;
      }
    }
  }
}

/** 
* CANVAS, TAGS
*/
.canvas {
  border-radius: 50%;
  max-width: 150px;
  max-height: 150px;
}

.expertise-field-container {
  border-bottom: 2px solid #f7f7f7;
}

/******************************/
/******* DocumentsUpload ******/
/******************************/
.document-upload-container {
  .to-upload-line {
    border-top: 1px solid #dddddd;
  }
  .subselect-toupload {
    // background-color: #f7f7f7;
    // border-radius: 3px;
    height: 70px;
  }
}

/******************************/
/***** TOAST notification *****/
/******************************/

.toast-notif {
  z-index: 10000;
  background-color: white;

  &.top-right {
    position: fixed;
    top: 20px;
    right: 20px;
  }
}

//underline missions
.underligne {
  border-bottom: 1px solid $grey-efe;
}
//BOX SHADOW NONE
.project-card {
  border: none;
  padding: 0;

  &:focus {
    outline: none !important;
    box-shadow: none;
  }
}

/******************************/
/***** Block de details projects *****/
/******************************/

.description-project {
  h2 {
    font-family: $din-light;
    font-size: 2.5rem;
  }

  h3 {
    color: #666666;
    font-family: $helvetica-reg;
    font-size: $size-18;
  }

  .see-on-web-container {
    background-color: #f7f7f7;
    padding: 15px;
  }

  .description-block-project {
    p {
      color: black;
      font-size: $size-18;
      font-family: $din-bold;
    }
  }
}

//input du calendrier

input[type="date"] {
  border: 2px solid #efefef;
  width: 100%;
}

.date-input::-webkit-datetime-edit {
  padding: 1em;
}

.date-input::-webkit-calendar-picker-indicator {
  display: none;
}

//LOADER

.height-min {
  height: 500px;
}

.ajax-progress {
  &.center {
    position: fixed;
    z-index: 99999999;
    text-align: center;
    width: 120px;
    height: 120px;
    top: 50%;
    left: 46.5%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 10px transparent;
    padding: 2rem;
  }

  &.alert {
    top: 10px;
    border-radius: 50%;
    border: 10px transparent;
    padding: 2rem;
  }

  background: rgba(255, 255, 255, 0.8) url("../images/logo/compass.svg") center no-repeat;
  background-size: 50%;
  -webkit-animation: rotating 0.5s linear infinite;
  -moz-animation: rotating 0.5s linear infinite;
  -ms-animation: rotating 0.5s linear infinite;
  -o-animation: rotating 0.5s linear infinite;
  animation: rotating 0.5s linear infinite;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
//Fin loader

//Tableau d'evaluations
table {
  thead {
    tr {
      th {
        width: 15%;
        span {
          height: 50px;
          display: flex;
          font-size: 0.9rem;
          justify-content: center;
          align-items: center;
        }
        &:first-of-type {
          width: 25%;
        }
      }
    }
  }
  .bordered-left {
    border-left: 2px solid #f7f7f7;
  }
  .bordered-right {
    border-left: 2px solid #f7f7f7;
  }
}

input[type="text"] {
  outline: none;
  &:not(.form-control-plaintext) {
    border: 2px solid #efefef;
  }
}
textarea {
  outline: none;
  &.form-control-plaintext {
    resize: none;
  }
}
